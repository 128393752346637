<template>
    <div class="" style="background-color: #333333; height: 100vh; width: 100%;overflow: hidden;">
        <img style="width: 100%;" src="./assets/mp4/to.png" alt="">

        <div class="box" style="margin-top: -40px;">
            <img style="height: 200px; width: 170px;" src="./assets/mp4/logo.png" alt="">


        </div>
        <div class="box">

            <span>{{ title }}</span>
        </div>
    </div>
    <!-- <div >

    </div> -->
</template>

<script>

export default {
    data() {
        return {
            title: "欢迎体验数字人小可"
        }
    }
}
</script>


<style scoped lang="less">
.box {
    width: 100%;
    display: flex;
    color: #fff !important;
    font-size: 28px;

    justify-content: center;
}
</style>